<template lang="pug">
  div.features__container
    div.features__inner
      div.features__content
        h2.title.features__title
          | ТЕХНІЧНІ ХАРАКТЕРИСТИКИ
        ul.features__list
          li(
            v-for="(item, index) in featuresList"
            :key="index"
          ).features__item {{ item }}

      div(ref="swiper__tech").price.swiper.tech__slider
        div.swiper-wrapper
          div(
            v-for="(item, index) in sliderList"
            :key="index"
          ).swiper-slide
            div.features__img.features__img--1
              div.img__wrapp
                img(
                  :src="item.image"
                  alt="scheme"
                )
        div.swiper-button-prev.tech__prev
        div.swiper-button-next.tech__next

    div.features__inner
      div.features__content
        h2.title.features__title ДОДАТКОВІ АКСЕСУАРИ
        div.features__text
          p Оберіть свою ідеальну комплектацію Drivelink для кращого досвіду користування пристроєм. Наші спеціалісти допоможуть підібрати опцію відповідно до Ваших вимог.
      div.features__img.features__img--2
        img(
          :src="FeaturesScheme"
          alt="scheme"
        )
</template>

<script>
// swiper
import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'
// images
import Features1 from "@/assets/images/features/01.svg"
import Features2 from "@/assets/images/features/02.svg"
import FeaturesScheme from "@/assets/images/features/03.svg"

export default {
  name: 'FeaturesBlock',
  data () {
    return {
      FeaturesScheme,
      featuresList: [
        'Аеродинамічний корпус',
        'Кут прийому сигналу: 100°',
        'Довжина по нижній грані: 640 мм',
        'Висота: 50 мм',
        'Магніти на корпусі: 4 - 8 шт.',
        'Притискна сила: 55 кг',
        'Підтримує швидкість руху авто: понад 160 км/год'
      ],
      sliderList: [
        { image: Features2 },
        { image: Features1 }
      ]
    }
  },
  mounted () {
    new Swiper(this.$refs.swiper__tech, {
      modules: [Navigation],
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  }
}
</script>
